import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    component: () => import("../views/Home.vue"),
  },

  // {
  //   path: "/:company",
  //   component: () => import("../views/Home.vue"),
  // },
  {
    path: "/character",
    component: () => import("../views/Characters.vue"),
  },
  {
    path: "/privacy",
    component: () => import("../views/Privacy.vue"),
  },
  {
    path: "/terms",
    component: () => import("../views/Terms.vue"),
  },
];

const router = createRouter({
  // URL 更加简洁和美观，没有 # 号。更符合 SEO 要求，因为搜索引擎可以更好地索引这种 URL。
  history: createWebHistory(), // 使用 模式的历史记录
  routes,
});

// 路由变更后通知 Google Analytics
router.afterEach((to) => {
  if (typeof gtag === "function") {
    gtag("config", "G-XEBT968LHF", {
      page_path: to.fullPath, // 当前访问的路径
    });
  }
});
export default router;
