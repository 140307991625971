import { createApp } from "vue";
import router from "./router";
import App from "./App.vue";
import "amfe-flexible"; // 引入 flexible.js
import Vant from 'vant';
import 'vant/lib/index.css';

import { showToast as toast } from "vant";

const app = createApp(App);
app.use(router);
app.use(Vant);
// 将别名 toast 挂载到 Vue 全局实例
app.config.globalProperties.$toast = toast;
app.mount("#app");
